import "./styles/index.scss";
// import { handleCookies } from "./scripts/cookies-helper";
import { cssVarSupport } from "./scripts/browser-helper";

cssVarSupport();

document.addEventListener("DOMContentLoaded", () => {
    // stripesAnimation();
    // handleCookies();

    window.onbeforeunload = () => {
        window.scrollTo(0, 0);
    };
});
